import { WeatherForecast } from '@/layouts/header/weather-forecast';
import { Link } from '@afishauz/core/widgets/link';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons/faCalendarDays';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslations } from 'next-intl';
import { Suspense } from 'react';
import { LocaleSwitch } from './locale-switch';

export const TopNavigation = () => {
  const t = useTranslations('common');

  return (
    <nav className='ml-9'>
      <ul className='flex'>
        <li>
          <WeatherForecast />
        </li>
        <li className='border-r border-l'>
          <Link href='/calendar' className='px-3 flex items-center gap-x-3'>
            <FontAwesomeIcon
              icon={faCalendarDays}
              aria-label='calendar'
              className='text-blue-600'
            />
            <div>
              <div className='text-sm'>
                {t('layouts.header.calendar.title')}
              </div>
              <div className='text-xs mt-1 text-gray-400'>
                {t('layouts.header.calendar.subtitle')}
              </div>
            </div>
          </Link>
        </li>
        <li>
          <Suspense>
            <LocaleSwitch />
          </Suspense>
        </li>
      </ul>
    </nav>
  );
};
