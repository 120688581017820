'use client';

import type { Theme, ThemeDevice } from '@afishauz/api/themes';
import { useEffect, useMemo } from 'react';
import { usePathname } from '../i18n/routing';
import { useLocale } from '../utils/i18n';

export function Themes({
  themes,
  device,
}: {
  themes: Theme[];
  device: ThemeDevice;
}) {
  const locale = useLocale();
  const pathname = usePathname();
  const filteredThemes = useMemo(
    () =>
      themes.filter(
        theme =>
          theme.devices.includes(device) &&
          pathname.includes(theme.urlPattern) &&
          theme.locales.includes(locale),
      ),
    [themes, pathname, locale, device],
  );

  useEffect(() => {
    const runScripts = () => {
      const scripts: HTMLScriptElement[] = [];
      for (const theme of filteredThemes) {
        for (const { url, '@id': iri } of theme.scripts) {
          const script = document.createElement('script');
          script.src = url;
          const id = extractId(iri);
          const attributes = theme.scriptAttributes[id] ?? {};
          for (const [name, value] of Object.entries(attributes)) {
            script.setAttribute(name, value);
          }
          scripts.push(document.body.appendChild(script));
        }
      }
      return () => {
        for (const script of scripts) {
          document.body.removeChild(script);
        }
      };
    };
    const applyStyles = () => {
      const links: HTMLLinkElement[] = [];
      for (const theme of filteredThemes) {
        for (const { url, '@id': iri } of theme.stylesheets) {
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = url;
          const id = extractId(iri);
          const attributes = theme.stylesheetAttributes[id] ?? {};
          for (const [name, value] of Object.entries(attributes)) {
            link.setAttribute(name, value);
          }
          links.push(document.head.appendChild(link));
        }
      }

      return () => {
        for (const link of links) {
          document.head.removeChild(link);
        }
      };
    };
    const removeScripts = runScripts();
    const removeLinks = applyStyles();
    return () => {
      removeScripts();
      removeLinks();
    };
  }, [filteredThemes]);
  return null;
}

function extractId(iri: string) {
  return Number(/(\d+)$/.exec(iri)?.[1] ?? -1);
}
