import type { EventSchedule as Schedule } from '@afishauz/api/event-schedules';
import dayjs from 'dayjs';
import { useTranslations } from 'next-intl';
import { useDateTranslator } from '../utils/date';

interface Props {
  className?: string;
  schedules: Schedule[];
  activeDate?: Date;
}

export const EventSchedule = ({ className, schedules, activeDate }: Props) => {
  const t = useTranslations('common');
  const { format } = useDateTranslator();

  if (schedules.length === 1) {
    const [schedule] = schedules;
    const startDate = new Date(schedule.startedAt);
    if (schedule.endedAt) {
      const endDate = new Date(schedule.endedAt);
      return (
        <span className={className}>
          {t('elements.event_schedule', {
            start: format(
              startDate,
              dayjs(startDate).isSame(endDate, 'month') ? 'D' : 'D MMMM',
            ),
            end: format(endDate, 'D MMMM'),
          })}
        </span>
      );
    }
    return (
      <span className={className}>
        {format(activeDate ?? startDate, 'D MMMM')}
      </span>
    );
  }

  const [schedule] = [...schedules].filter(schedule => {
    if (schedule.isExactStartTime) {
      return new Date(schedule.startedAt) >= new Date();
    }
    return dayjs(new Date(schedule.startedAt)).diff(new Date(), 'days') >= 0;
  });

  if (!schedule) {
    const lastSchedule = schedules[schedules.length - 1];
    const startDate = new Date(lastSchedule.startedAt);
    if (lastSchedule.endedAt) {
      const endDate = new Date(lastSchedule.endedAt);
      return (
        <span className={className}>
          {t('elements.event_schedule', {
            start: format(
              startDate,
              dayjs(startDate).isSame(endDate) ? 'D' : 'D MMMM',
            ),
            end: format(endDate, 'D MMMM'),
          })}
        </span>
      );
    }
    return (
      <span className={className}>
        {format(activeDate ?? startDate, 'D MMMM')}
      </span>
    );
  }

  return (
    <span className={className}>
      {format(activeDate ?? new Date(schedule.startedAt), 'D MMMM')}
    </span>
  );
};
