import { type MediaObjectVariant, getMediaObjectUrl } from '../media-objects';
import type { Performance } from './types';

export function getPerformancePoster(
  performance: Performance,
  variant: MediaObjectVariant,
) {
  return (
    (performance.mainMediaObject &&
      getMediaObjectUrl(performance.mainMediaObject, variant)) ??
    performance.mainMediaObject?.url
  );
}

export function getPerformanceLink(performance: Performance) {
  return `/performances/${performance.slug}`;
}
