'use client';

import { useCallback, useState } from 'react';

export type AdvancedSearchFilter =
  | 'events'
  | 'videos'
  | 'places'
  | 'materials'
  | 'performances';

export const useAdvancedSearchFilter = () => {
  const [filter, setFilter] = useState<AdvancedSearchFilter | undefined>(() => {
    if (typeof window === 'undefined') {
      return;
    }
    return new URL(window.location.href).searchParams.get(
      'filter',
    ) as AdvancedSearchFilter;
  });

  const onFilterChange = useCallback((filter: AdvancedSearchFilter) => {
    setFilter(filter);
    const url = new URL(window.location.href);
    url.searchParams.set('filter', filter);
    history.replaceState(history.state, '', url);
  }, []);

  const onFilterClear = useCallback(() => {
    setFilter(undefined);
    const url = new URL(window.location.href);
    url.searchParams.delete('filter');
    history.replaceState(history.state, '', url);
  }, []);

  return { filter, onFilterChange, onFilterClear };
};

export function isShortSearchQuery(str: string, minLength = 3) {
  return str
    .trim()
    .split(/\s+/)
    .filter(str => str.trim().length > 0)
    .every(str => str.length < minLength);
}
