import(/* webpackMode: "eager" */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/core/styles/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["PushNotificationsProvider"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/core/utils/fcm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/core/utils/user.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FloatingAdBanner"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/core/widgets/floating-ad-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleTagManager"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/core/widgets/google-tag-manager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProgressBar"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/core/widgets/progress-bar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ResourcePreloader"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/core/widgets/resource-preloader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Themes"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/core/widgets/themes.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["YandexMetrika"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/core/widgets/yandex-metrika.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/desktop/src/layouts/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/desktop/src/layouts/header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HorizontalBanner"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/desktop/src/templates/shared/horizontal-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PushNotifDialog"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/apps/desktop/src/templates/shared/push-notif-dialog.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/node_modules/.pnpm/@fortawesome+fontawesome-svg-core@6.6.0/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/node_modules/.pnpm/next-intl@3.19.1_next@14.2.8_@playwright+test@1.43.1_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/node_modules/.pnpm/next-intl@3.19.1_next@14.2.8_@playwright+test@1.43.1_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/node_modules/.pnpm/next@14.2.8_@playwright+test@1.43.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\",\"cyrillic\"],\"weight\":[\"400\",\"500\",\"600\"]}],\"variableName\":\"fontFamily\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/runner/work/afisha-web-frontend/afisha-web-frontend/node_modules/.pnpm/react-hot-toast@2.4.1_csstype@3.1.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-hot-toast/dist/index.mjs");
