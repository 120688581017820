import { useCurrentWeatherForecasts } from '@afishauz/api/weather-forecasts';
import { useLocale } from '@afishauz/core/utils/i18n';
import { Sun } from '@afishauz/icons';

export const WeatherForecast = () => {
  const locale = useLocale();
  const { list, isLoadingFirstPage } = useCurrentWeatherForecasts({
    keyParams: {
      'city[slug]': 'tashkent',
    },
  });

  if (list.filter(Boolean).length === 0) {
    return null;
  }

  const link = locale === 'uz' ? 'https://obhavo.uz/' : 'https://pogoda.uz';

  const daily = list.find(item => item.type === 'DAILY');
  const hourly = list.find(item => item.type === 'HOURLY');

  if (isLoadingFirstPage) {
    return null;
  }

  return (
    <a
      href={link}
      target='_blank'
      rel='noreferrer'
      className='flex items-center gap-x-3 pr-3'
      aria-label='weather forecast'
    >
      <Sun width={20} className='text-[#F3ba08]' />
      <div>
        {!!hourly && <div className='text-sm'>{hourly.lowTemperature}°C</div>}
        {!!daily && (
          <div className='text-xs'>
            {daily.highTemperature}°&nbsp;
            <span className='text-gray-400'>{daily.lowTemperature}°</span>
          </div>
        )}
      </div>
    </a>
  );
};
