'use client';

import { createSearchParams } from '@afishauz/lib/url';
import useSWRImmutable from 'swr/immutable';
import type { GetCollectionOptions } from '../collection';
import type { Locale } from '../config';
import { httpClient } from '../http-client';
import {
  type SWROptions,
  type UseCollectionOptions,
  useCollection,
} from '../use-collection';
import type { GetMaterialsParams, SearchMaterialsParams } from './service';
import type { Material } from './types';

export function useMaterials(
  options: UseCollectionOptions<
    Material,
    Omit<GetMaterialsParams, 'page'>
  > = {},
  requestOptions: GetCollectionOptions = {},
  swrConfig: SWROptions = {},
) {
  return useCollection<Material>(
    'api/materials',
    options,
    requestOptions,
    swrConfig,
  );
}

export function useMaterialsSearch(
  options: UseCollectionOptions<
    Material,
    Omit<SearchMaterialsParams, 'page'>
  > = {},
  requestOptions: GetCollectionOptions = {},
  swrConfig: SWROptions = {},
) {
  return useCollection<Material>(
    'api/materials/search',
    options,
    requestOptions,
    swrConfig,
  );
}

export function useMaterialView(permalink: string | null, locale: Locale) {
  return useSWRImmutable(
    permalink ? ['api/materials/view', locale, permalink] : null,
    ([url, locale, permalink]) =>
      httpClient.put(url, {
        searchParams: createSearchParams({ locale, permalink }),
      }),
  );
}
