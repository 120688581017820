import { type VariantProps, cva } from 'class-variance-authority';
import { type InputHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const checkbox = cva(
  [
    'transition-colors',
    'relative',
    'appearance-none',
    'bg-gray-100',
    '[&:not(:checked)]:border',
    'border-gray-300',
    'rounded',
    'checked:after:content-[""]',
    'checked:after:absolute',
    'checked:after:top-1/2',
    'checked:after:left-1/2',
    'checked:after:w-3/5',
    'checked:after:h-[30%]',
    'checked:after:border-b-2',
    'checked:after:border-b-white',
    'checked:after:border-l-2',
    'checked:after:border-l-white',
    'checked:after:translate-x-[-50%]',
    'checked:after:translate-y-[-65%]',
    'checked:after:-rotate-45',
    'disabled:bg-gray-200',
    'disabled:border-gray-100',
  ],
  {
    variants: {
      color: {
        default: ['checked:bg-gray-400'],
        primary: ['checked:bg-afisha'],
        secondary: ['checked:bg-blue-500'],
      },
      size: {
        sm: ['w-4', 'h-4'],
        md: ['w-5', 'h-5'],
        lg: ['w-6', 'h-6'],
      },
    },
    defaultVariants: {
      color: 'default',
      size: 'md',
    },
  },
);

export type CheckboxProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'size' | 'color'
> &
  VariantProps<typeof checkbox>;

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ color, size, className, ...rest }, ref) => {
    return (
      <input
        {...rest}
        type='checkbox'
        ref={ref}
        className={twMerge(checkbox({ color, size, className }))}
      />
    );
  },
);

Checkbox.displayName = 'Checkbox';
