import { Events } from '@/layouts/header/search/events';
import { Materials } from '@/layouts/header/search/materials';
import { Performances } from '@/layouts/header/search/performances';
import { Places } from '@/layouts/header/search/places';
import { Videos } from '@/layouts/header/search/videos';
import {
  useSearchMaterials,
  useSearchPerformances,
  useSearchPlaces,
  useSearchVideos,
} from '@afishauz/core/pages/search';
import { useLocale } from '@afishauz/core/utils/i18n';
import { useRouteChange } from '@afishauz/core/utils/router';
import { isShortSearchQuery } from '@afishauz/core/utils/search';
import { Alert } from '@afishauz/ui-kit/alert';
import { Box } from '@afishauz/ui-kit/box';
import { Spinner } from '@afishauz/ui-kit/spinner';
import { TextInput } from '@afishauz/ui-kit/text-input';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import {
  type ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export const Search = () => {
  const t = useTranslations('common');
  const locale = useLocale();
  const searchMenuRef = useRef<HTMLDivElement>(null);
  const [searchMenuHeight, setSearchMenuHeight] = useState<number>();
  const [fuzzy, setFuzzy] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { isLoadingFirstPage: eventsLoading, totalItems: eventsTotalItems } =
    useSearchMaterials({
      q: searchQuery,
      locale,
      fuzzy,
    });
  const { isLoadingFirstPage: videosLoading, totalItems: videosTotalItems } =
    useSearchVideos({ q: searchQuery, locale, fuzzy });
  const {
    isLoadingFirstPage: materialsLoading,
    totalItems: materialsTotalItems,
  } = useSearchMaterials({
    q: searchQuery,
    locale,
    fuzzy,
  });
  const {
    isLoadingFirstPage: performancesLoading,
    totalItems: performancesTotalItems,
  } = useSearchPerformances({ q: searchQuery, locale, fuzzy });
  const { isLoadingFirstPage: placesLoading, totalItems: placesTotalItems } =
    useSearchPlaces({ q: searchQuery, locale, fuzzy });
  const [searchDropdownOpened, setSearchDropdownOpened] = useState(
    searchQuery.length > 0,
  );
  const searchField = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (searchMenuRef.current) {
      const height =
        window.innerHeight -
        searchMenuRef.current.getBoundingClientRect().y -
        50;
      setSearchMenuHeight(height);
    }
  }, []);

  const noResults = useCallback(() => {
    return (
      materialsTotalItems === 0 &&
      !materialsLoading &&
      eventsTotalItems === 0 &&
      !eventsLoading &&
      videosTotalItems === 0 &&
      !videosLoading &&
      performancesTotalItems === 0 &&
      !performancesLoading &&
      placesTotalItems === 0 &&
      !placesLoading
    );
  }, [
    materialsTotalItems,
    materialsLoading,
    eventsTotalItems,
    eventsLoading,
    videosTotalItems,
    videosLoading,
    performancesTotalItems,
    performancesLoading,
    placesTotalItems,
    placesLoading,
  ]);

  useEffect(() => {
    if (noResults()) {
      setFuzzy(true);
    }
  }, [noResults]);

  const closeSearchDropdown = useCallback(() => {
    setSearchDropdownOpened(false);
  }, []);

  useEffect(() => {
    setSearchDropdownOpened(searchQuery.length > 0);
  }, [searchQuery.length]);

  useEffect(() => {
    window.addEventListener('click', closeSearchDropdown);
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        searchField.current?.blur();
        closeSearchDropdown();
      }
    };
    window.addEventListener('keydown', handleKeydown);
    return () => {
      window.removeEventListener('click', closeSearchDropdown);
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [closeSearchDropdown]);

  useRouteChange(closeSearchDropdown);

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div
      onClick={event => {
        event.stopPropagation();
      }}
      className='relative flex w-96'
    >
      <TextInput
        ref={searchField}
        type='search'
        placeholder={t('layouts.search.placeholder').toString()}
        value={searchQuery}
        onChange={handleQueryChange}
        className='w-full'
        icon={<FontAwesomeIcon icon={faSearch} />}
        onFocus={() => {
          if (searchQuery.trim().length > 0) {
            setSearchDropdownOpened(true);
          }
        }}
      />
      <div
        className={cx(
          'absolute top-[calc(100%+8px)] w-full overflow-hidden shadow-afisha-md pl-2 pr-0 bg-white z-50 animate-fade-in rounded-md',
          !searchDropdownOpened && 'hidden',
        )}
        ref={searchMenuRef}
      >
        <div
          className='h-full max-h-72 overflow-y-auto pr-2'
          style={{
            maxHeight: searchMenuHeight,
          }}
        >
          {isShortSearchQuery(searchQuery) ? (
            <Box>
              <Alert type='info' size='large'>
                {t('layouts.search.short_query')}
              </Alert>
            </Box>
          ) : (
            <>
              {fuzzy && noResults() && (
                <Box>
                  <Alert type='info' size='large'>
                    {t('layouts.search.no_results', { query: searchQuery })}
                  </Alert>
                </Box>
              )}
              {materialsLoading ||
              eventsLoading ||
              videosLoading ||
              performancesLoading ||
              placesLoading ? (
                <div className='flex justify-center py-4'>
                  <Spinner size={20} />
                </div>
              ) : (
                <>
                  <Events query={searchQuery} fuzzy={fuzzy} />
                  <Videos query={searchQuery} fuzzy={fuzzy} />
                  <Materials query={searchQuery} fuzzy={fuzzy} />
                  <Performances query={searchQuery} fuzzy={fuzzy} />
                  <Places query={searchQuery} fuzzy={fuzzy} />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
