'use client';

import { usePathname } from '@afishauz/core/i18n/routing';
import { useAdvertisement } from '@afishauz/core/utils/ads';
import { useRouteChange } from '@afishauz/core/utils/router';
import { Link } from '@afishauz/core/widgets/link';
import { type VariantProps, cva } from 'class-variance-authority';
import { useTranslations } from 'next-intl';

const banner = cva(
  [
    'relative overflow-hidden w-full bg-gray-50 p-6 flex justify-center items-center h-[300px]',
  ],
  {
    variants: {
      spacing: {
        none: ['my-0'],
        sm: ['my-2'],
        md: ['my-4'],
        lg: ['my-8'],
      },
    },
    defaultVariants: {
      spacing: 'lg',
    },
  },
);

interface Props extends VariantProps<typeof banner> {
  zoneId: number;
  hideOnPaths?: (string | RegExp)[];
}

export const HorizontalBanner = ({
  zoneId,
  spacing,
  hideOnPaths = [],
}: Props) => {
  const t = useTranslations('common');
  const pathname = usePathname();
  const { data, update, ref } = useAdvertisement({
    zoneId,
  });

  useRouteChange(update);

  if (hideOnPaths.some(regex => pathname.match(regex))) {
    return null;
  }

  return (
    <aside className={banner({ spacing })} ref={ref}>
      {data ? (
        <a href={data.clickUrl} target='_blank' rel='noreferrer'>
          <img
            src={data.creativeUrl}
            width={data.width}
            height={data.height}
            alt='ad'
          />
        </a>
      ) : (
        <Link
          href='/reklama'
          className='text-sm text-gray-500 hover:text-gray-700 transition-colors hover:underline'
        >
          {t('elements.ad')}
        </Link>
      )}
    </aside>
  );
};
