import type { GetCollectionOptions } from '../collection';
import {
  type SWROptions,
  type UseCollectionOptions,
  useCollection,
} from '../use-collection';
import type { GetPlacesParams, SearchPlaceParams } from './service';
import type { Place } from './types';

export function usePlaces(
  options: UseCollectionOptions<Place, Omit<GetPlacesParams, 'page'>> = {},
  requestOptions: GetCollectionOptions = {},
  swrConfig: SWROptions = {},
) {
  return useCollection<Place>('api/places', options, requestOptions, swrConfig);
}

export function usePlacesSearch(
  options: UseCollectionOptions<Place, Omit<SearchPlaceParams, 'page'>> = {},
  requestOptions: GetCollectionOptions = {},
  swrConfig: SWROptions = {},
) {
  return useCollection<Place>(
    'api/places/search',
    options,
    requestOptions,
    swrConfig,
  );
}
