'use client';

import {
  GoogleTagManager as GTM,
  sendGTMEvent,
} from '@next/third-parties/google';
import { useEffect } from 'react';
import { useLocale } from '../utils/i18n';

export function GoogleTagManager() {
  const locale = useLocale();

  useEffect(() => {
    sendGTMEvent({ lang: locale });
  }, [locale]);

  return <GTM gtmId='GTM-TQ6H8PK' />;
}
