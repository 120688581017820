import { type MediaObjectVariant, getMediaObjectUrl } from '../media-objects';
import type { Video } from './types';

export function getVideoLink(video: Video) {
  return `/movies/${video.slug}`;
}

export function getVideoPoster(video: Video, variant: MediaObjectVariant) {
  return (
    (video.mainMediaObject &&
      getMediaObjectUrl(video.mainMediaObject, variant)) ??
    video.mainMediaObject?.url
  );
}

export function getVideoFirstFrame(video: Video, variant: MediaObjectVariant) {
  return (
    (video.firstImageMediaObject &&
      getMediaObjectUrl(video.firstImageMediaObject, variant)) ||
    undefined
  );
}
