import { ListItem, ListTitle } from '@/layouts/header/search/list';
import {
  getMaterialMainImage,
  getMaterialSection,
} from '@afishauz/api/materials';
import { useSearchEvents } from '@afishauz/core/pages/search';
import { useLocale } from '@afishauz/core/utils/i18n';
import { EventSchedule } from '@afishauz/core/widgets/event-schedule';
import { LoadingButton } from '@afishauz/core/widgets/loading-button';
import { PublishedAt } from '@afishauz/core/widgets/published-at';
import { Box } from '@afishauz/ui-kit/box';
import {
  DelimitedList,
  DelimitedListItem,
} from '@afishauz/ui-kit/delimited-list';
import { List, ListItemMeta, ListItemTitle } from '@afishauz/ui-kit/list';
import { useTranslations } from 'next-intl';

interface Props {
  query: string;
  fuzzy?: boolean;
}

export const Events = ({ query, fuzzy = false }: Props) => {
  const t = useTranslations('common');
  const locale = useLocale();
  const { list, isValidating, hasMoreItems, getNextPage, totalItems } =
    useSearchEvents({ q: query, locale, fuzzy });

  if (totalItems === 0) {
    return null;
  }

  return (
    <Box component='section'>
      <ListTitle>{t('layouts.search.events')}</ListTitle>
      <List>
        {list.map(material => (
          <ListItem
            link={{
              href: material.permalinkCanonical,
              native: material.tildaSubstitute,
            }}
            img={{
              src: getMaterialMainImage(material, 'extra_small'),
              alt: material.title,
            }}
            key={material['@id']}
          >
            <ListItemTitle title={material.title} size='sm'>
              {material.title}
            </ListItemTitle>
            <ListItemMeta className='text-xs'>
              <PublishedAt date={new Date(material.publishedAt)} />
            </ListItemMeta>
            <ListItemMeta className='text-xs'>
              <DelimitedList>
                <DelimitedListItem>
                  {getMaterialSection(material).name}
                </DelimitedListItem>
                <DelimitedListItem>
                  <EventSchedule schedules={material.eventSchedules} />
                </DelimitedListItem>
              </DelimitedList>
            </ListItemMeta>
          </ListItem>
        ))}
      </List>
      {hasMoreItems && (
        <LoadingButton
          loading={isValidating}
          onClick={getNextPage}
          disabled={isValidating}
          className='mt-3'
          size='sm'
        />
      )}
    </Box>
  );
};
