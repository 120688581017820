import { ListItem, ListTitle } from '@/layouts/header/search/list';
import { getPlaceLink, getPlaceMainImage } from '@afishauz/api/places';
import { useSearchPlaces } from '@afishauz/core/pages/search';
import { useLocale } from '@afishauz/core/utils/i18n';
import { LoadingButton } from '@afishauz/core/widgets/loading-button';
import { Box } from '@afishauz/ui-kit/box';
import {
  DelimitedList,
  DelimitedListItem,
} from '@afishauz/ui-kit/delimited-list';
import { List, ListItemMeta, ListItemTitle } from '@afishauz/ui-kit/list';
import { useTranslations } from 'next-intl';

interface Props {
  query: string;
  fuzzy?: boolean;
}

export const Places = ({ query, fuzzy = false }: Props) => {
  const t = useTranslations('common');
  const locale = useLocale();
  const { list, isValidating, getNextPage, hasMoreItems, totalItems } =
    useSearchPlaces({ q: query, locale, fuzzy });

  if (totalItems === 0) {
    return null;
  }

  return (
    <Box component='section'>
      <ListTitle>{t('layouts.search.places')}</ListTitle>
      <List>
        {list.map(place => (
          <ListItem
            link={{ href: getPlaceLink(place) }}
            img={{
              src: getPlaceMainImage(place, 'extra_small'),
              alt: place.name,
              size: 'sm',
            }}
            key={place['@id']}
          >
            <ListItemTitle title={place.name} size='sm'>
              {place.name}
            </ListItemTitle>
            <ListItemMeta>
              <DelimitedList>
                {place.types && place.types.length > 0 && (
                  <DelimitedListItem>{place.types[0].name}</DelimitedListItem>
                )}
                {place.address && (
                  <DelimitedListItem>{place.address}</DelimitedListItem>
                )}
              </DelimitedList>
            </ListItemMeta>
          </ListItem>
        ))}
      </List>
      {hasMoreItems && (
        <LoadingButton
          loading={isValidating}
          onClick={getNextPage}
          disabled={isValidating}
          className='mt-3'
          size='sm'
        />
      )}
    </Box>
  );
};
