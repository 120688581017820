'use client';

import Script from 'next/script';
import { usePathname } from '../i18n/routing';
import { useYmHit } from '../utils/analytics';

export function YandexMetrika() {
  const pathname = usePathname();
  useYmHit(pathname);

  return (
    <>
      <Script
        id='ym-init'
        dangerouslySetInnerHTML={{
          __html: `
             (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
             m[i].l=1*new Date();
             for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
             k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
             (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
             ym(9561514, "init", {
                defer: true,
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true,
             });
          `,
        }}
      />
      <noscript>
        <div>
          <img
            src='https://mc.yandex.ru/watch/9561514'
            style={{ position: 'absolute', left: -9999 }}
            alt=''
          />
        </div>
      </noscript>
    </>
  );
}
