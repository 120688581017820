import { formatTz, getTashkentDate } from '@afishauz/lib/date';
import type { useTranslations } from 'next-intl';
import { API_ORIGIN, DEFAULT_PREVIEW } from '../config';
import type { EventSchedule } from '../event-schedules';
import { type MediaObjectVariant, getMediaObjectUrl } from '../media-objects';
import type { Material } from './types';

export function getMaterialSection(material: Material) {
  return material.sections[0];
}

export function getMaterialMainImage(
  material: Material,
  variant: MediaObjectVariant,
) {
  return (
    (material.mainMediaObject &&
      getMediaObjectUrl(material.mainMediaObject, variant)) ??
    material.mainMediaObject?.url
  );
}

export const getMaterialTypeName = (
  material: Material,
  t: ReturnType<typeof useTranslations>,
) => {
  switch (material.type) {
    case 'ARTICLE':
      return t('elements.material_type.article');
    case 'EVENT':
      return t('elements.material_type.event');
    case 'PHOTOSET':
      return t('elements.material_type.photoset');
    case 'NEWS':
      return t('elements.material_type.news');
    case 'VIDEO':
      return t('elements.material_type.video');
  }
  return t('elements.material_type.material');
};

type StructuredData = Record<string, unknown>;

export function getMaterialStructuredData(
  material: Material,
  t: ReturnType<typeof useTranslations>,
) {
  const city = material.eventSchedules[0]?.place?.city?.nameGenitive;
  const materialTitle = material.title;
  const name =
    material.type === 'EVENT' && city
      ? t('meta.title.event', {
          materialTitle,
          city,
        })
      : t('meta.title.common', { materialTitle });

  const commonSchema = {
    '@context': 'https://schema.org',
    name,
    description: material.intro || undefined,
    image: new URL(
      `${getMaterialMainImage(material, 'medium') ?? DEFAULT_PREVIEW}`,
      API_ORIGIN,
    ).toString(),
    url: new URL(
      `${material.locale}${material.permalinkCanonical}`,
      API_ORIGIN,
    ).toString(),
  };
  if (material.type === 'EVENT') {
    const data: StructuredData = {
      ...commonSchema,
      '@type': 'Event',
    };
    const { eventSchedules } = material;
    const eventSchedule =
      eventSchedules.find(eventSchedule => {
        const today = getTashkentDate(new Date());
        today.setHours(0, 0, 0, 0);
        if (eventSchedule.endedAt) {
          return (
            new Date(eventSchedule.endedAt) >= today &&
            new Date(eventSchedule.startedAt) <= today
          );
        }
        return new Date(eventSchedule.startedAt) >= today;
      }) ?? eventSchedules[eventSchedules.length - 1];
    if (eventSchedule) {
      data.startDate = getFormattedStartDate(eventSchedule);
      data.endDate = getFormattedEndDate(eventSchedule);
      const { place } = eventSchedule;
      if (!place) {
        return null;
      }
      const address =
        [place.city?.name, place.address].filter(Boolean).join(', ') ||
        undefined;
      data.location = {
        '@type': 'Place',
        name: place.name,
        address,
        geo:
          Number(place.latitude) && Number(place.longitude)
            ? {
                '@type': 'GeoCoordinates',
                latitude: place.latitude,
                longitude: place.longitude,
              }
            : undefined,
      };
    }
    return data;
  }
  const data: StructuredData = {
    ...commonSchema,
    '@type': getSchemaType(material),
    headline: material.title,
    datePublished: material.publishedAt,
  };
  if (material.author) {
    data.author = material.author.fullName;
  }
  if (material.authorCustom) {
    data.author = material.authorCustom;
  }
  return data;
}

function getSchemaType(material: Material) {
  const { type } = material;
  switch (type) {
    case 'ARTICLE':
    case 'PHOTOSET':
      return 'https://schema.org/Article';
    case 'NEWS':
      return 'https://schema.org/NewsArticle';
    default:
      return 'https://schema.org/CreativeWork';
  }
}

function getFormattedStartDate(eventSchedule: EventSchedule) {
  const { startedAt, isExactStartTime } = eventSchedule;
  if (isExactStartTime) {
    return startedAt;
  }
  return formatTz(getTashkentDate(new Date(startedAt)), 'YYYY-MM-DD');
}

function getFormattedEndDate(eventSchedule: EventSchedule) {
  const { endedAt, isExactEndTime } = eventSchedule;
  if (!endedAt) {
    return undefined;
  }
  if (isExactEndTime) {
    return endedAt;
  }
  return formatTz(getTashkentDate(new Date(endedAt)), 'YYYY-MM-DD');
}
