'use client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { subscribe, unsubscribe, usePushNotifications } from '../utils/fcm';
('@radix-ui/react-dialog');
import { Button } from '@afishauz/ui-kit/button';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@afishauz/ui-kit/dialog';
import { Tooltip } from '@afishauz/ui-kit/tooltip';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faBellSlash } from '@fortawesome/free-solid-svg-icons/faBellSlash';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import { useLocale } from '../utils/i18n';

export function PushSubscribeButton({ mobile }: { mobile?: boolean }) {
  const t = useTranslations('common');
  const locale = useLocale();
  const [subscribing, setSubscribing] = useState(false);
  const { status, updateStatus } = usePushNotifications();

  if (status === null) {
    return null;
  }

  if (status === 'unsupported') {
    return (
      <Tooltip
        title={t(
          'layouts.desktop_footer.push_notifications_dialog.status.unsupported',
        )}
        position='bottom'
      >
        <button
          type='button'
          disabled
          className='text-xl cursor-not-allowed'
          aria-label={t(
            'layouts.desktop_footer.push_notifications_dialog.status.unsupported',
          )}
        >
          <FontAwesomeIcon icon={faBellSlash} />
        </button>
      </Tooltip>
    );
  }

  const subscribeToPushNotifications = async () => {
    setSubscribing(true);
    const success = await subscribe(locale);
    await updateStatus();
    setSubscribing(false);
    if (success) {
      toast.success(t('elements.push_notifications.messages.success'));
    } else {
      toast.error(t('elements.push_notifications.messages.error'));
    }
  };

  const unsubscribeFromPushNotifications = async () => {
    setSubscribing(true);
    await unsubscribe();
    await updateStatus();
    setSubscribing(false);
  };

  const buttonTitle = t(
    `layouts.desktop_footer.push_notifications_dialog.status.${
      status === 'subscribed' ? 'enabled' : 'disabled'
    }`,
  );

  return (
    <Dialog size={mobile ? 'xs' : 'md'}>
      <DialogTrigger
        title={buttonTitle}
        className={cx(
          'text-xl',
          status === 'unsubscribed' && 'text-amber-300',
          status === 'subscribed' && 'text-teal-500',
        )}
        aria-label='subscribe to push notifications'
      >
        <FontAwesomeIcon icon={faBell} />
      </DialogTrigger>
      <DialogContent>
        {status === 'subscribed' ? (
          <>
            <DialogTitle className='mb-6'>
              {t('layouts.desktop_footer.push_notifications_dialog.subscribed')}
            </DialogTitle>
            <div className='flex justify-end'>
              <Button
                onClick={unsubscribeFromPushNotifications}
                disabled={subscribing}
                fullWidth={mobile}
              >
                {t(
                  'layouts.desktop_footer.push_notifications_dialog.unsubscribe',
                )}
              </Button>
            </div>
          </>
        ) : (
          <>
            <DialogTitle className='mb-6'>
              {t(
                'layouts.desktop_footer.push_notifications_dialog.want_to_subscribe',
              )}
            </DialogTitle>
            <div className='flex justify-end'>
              <Button
                color='primary'
                onClick={subscribeToPushNotifications}
                disabled={subscribing}
                fullWidth={mobile}
              >
                {t(
                  'layouts.desktop_footer.push_notifications_dialog.subscribe',
                )}
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
