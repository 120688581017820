import { ImageWithFallback } from '@afishauz/core/widgets/image-with-fallback';
import { Link } from '@afishauz/core/widgets/link';
import type { ListItemProps } from '@afishauz/core/widgets/list-item';
import { Text } from '@afishauz/ui-kit/text';
import type { PropsWithChildren } from 'react';

export const ListItem = ({ link, img = {}, children }: ListItemProps) => {
  return (
    <li className='w-full'>
      <Link href={link.href} nativeLink={link.native} className='block'>
        <article className='flex items-start gap-x-3 p-3 rounded-md hover:bg-gray-100'>
          <div className='min-w-[57px] w-[57px] h-[38px]'>
            <ImageWithFallback
              src={img.src}
              alt={img.alt}
              className='rounded'
            />
          </div>
          <div className='truncate'>{children}</div>
        </article>
      </Link>
    </li>
  );
};

export const ListTitle = ({ children }: PropsWithChildren) => {
  return (
    <Text
      component='h2'
      weight={500}
      className='pb-1 mb-2 mx-3 border-b border-b-gray-200'
    >
      {children}
    </Text>
  );
};
