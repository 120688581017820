import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { Text, type TextProps } from '../text';

export type ListItemTitleProps = TextProps;

export const ListItemTitle = forwardRef<HTMLHeadingElement, ListItemTitleProps>(
  ({ weight = 500, lineClamp = 3, ...rest }, ref) => {
    return (
      <Text
        ref={ref}
        component='h3'
        {...rest}
        weight={weight}
        lineClamp={lineClamp}
      />
    );
  },
);

ListItemTitle.displayName = 'ListItemTitle';
