'use client';

import { type PropsWithChildren, createContext, useContext } from 'react';

export type Delimiter = 'dot';

const DelimitedListContext = createContext<Delimiter>('dot');

export interface DelimitedListProps extends PropsWithChildren {
  delimiter?: Delimiter;
}

export const DelimitedList = ({
  children,
  delimiter = 'dot',
}: DelimitedListProps) => {
  return (
    <DelimitedListContext.Provider value={delimiter}>
      <ul className='flex'>{children}</ul>
    </DelimitedListContext.Provider>
  );
};

export const DelimitedListItem = ({ children }: PropsWithChildren) => {
  const delimiter = useContext(DelimitedListContext);
  const classNamesMap: Record<Delimiter, string> = {
    dot: 'after:content-["•"]',
  };
  return (
    <li
      className={`${classNamesMap[delimiter]} after:inline-block after:px-2 last:after:content-none truncate first:flex-none last:flex-1`}
    >
      {children}
    </li>
  );
};
