import { Button, type ButtonProps } from '@afishauz/ui-kit/button';
import { Spinner } from '@afishauz/ui-kit/spinner';
import { useTranslations } from 'next-intl';
import { forwardRef } from 'react';

export interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
}

export const LoadingButton = forwardRef<HTMLButtonElement, LoadingButtonProps>(
  ({ loading, ...rest }, ref) => {
    const t = useTranslations('common');
    return (
      <Button {...rest} fullWidth ref={ref}>
        {t('elements.loading_button.text')}
        {loading && (
          <>
            &nbsp;
            <Spinner size={16} />
          </>
        )}
      </Button>
    );
  },
);

LoadingButton.displayName = 'LoadingButton';
