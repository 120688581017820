import { ListItem, ListTitle } from '@/layouts/header/search/list';
import {
  getPerformanceLink,
  getPerformancePoster,
} from '@afishauz/api/performances';
import { useSearchPerformances } from '@afishauz/core/pages/search';
import { useLocale } from '@afishauz/core/utils/i18n';
import { LoadingButton } from '@afishauz/core/widgets/loading-button';
import { Box } from '@afishauz/ui-kit/box';
import {
  DelimitedList,
  DelimitedListItem,
} from '@afishauz/ui-kit/delimited-list';
import { List, ListItemMeta, ListItemTitle } from '@afishauz/ui-kit/list';
import { useTranslations } from 'next-intl';

interface Props {
  query: string;
  fuzzy?: boolean;
}

export const Performances = ({ query, fuzzy = false }: Props) => {
  const t = useTranslations('common');
  const locale = useLocale();
  const { list, isValidating, getNextPage, hasMoreItems, totalItems } =
    useSearchPerformances({ q: query, fuzzy, locale });

  if (totalItems === 0) {
    return null;
  }

  return (
    <Box component='section'>
      <ListTitle>{t('layouts.search.performances')}</ListTitle>
      <List>
        {list.map(performance => (
          <ListItem
            link={{ href: getPerformanceLink(performance) }}
            img={{
              src: getPerformancePoster(performance, 'extra_small'),
              alt: performance.title,
              size: 'sm',
            }}
            key={performance['@id']}
          >
            <ListItemTitle title={performance.title} size='sm'>
              {performance.title}
            </ListItemTitle>
            <ListItemMeta>
              <DelimitedList>
                {performance.directors && performance.directors.length > 0 && (
                  <DelimitedListItem>
                    {performance.directors
                      .map(director => director.name)
                      .join(', ')}
                  </DelimitedListItem>
                )}
                {performance.genres && performance.genres.length > 0 && (
                  <DelimitedListItem>
                    {performance.genres
                      .map(genre => genre.name.toLowerCase())
                      .join(', ')}
                  </DelimitedListItem>
                )}
              </DelimitedList>
            </ListItemMeta>
          </ListItem>
        ))}
      </List>
      {hasMoreItems && (
        <LoadingButton
          loading={isValidating}
          onClick={getNextPage}
          disabled={isValidating}
          className='mt-3'
          size='sm'
        />
      )}
    </Box>
  );
};
