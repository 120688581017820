'use client';

import type { MenuItem } from '@afishauz/api/menu-items';
import { SOCIAL_MEDIA } from '@afishauz/core/config';
import { useLocale } from '@afishauz/core/utils/i18n';
import { Link } from '@afishauz/core/widgets/link';
import { PushSubscribeButton } from '@afishauz/core/widgets/push-subscribe-button';
import { Afishauz } from '@afishauz/icons';
import { Container } from '@afishauz/ui-kit/container';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faTelegram } from '@fortawesome/free-brands-svg-icons/faTelegram';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslations } from 'next-intl';

export function Footer({ menuItems = [] }: { menuItems: MenuItem[] }) {
  const t = useTranslations('common');
  const locale = useLocale();

  if (menuItems.length === 0) {
    return null;
  }

  return (
    <footer className='py-12 mt-8'>
      <Container>
        <div className='flex items-end justify-between mb-8'>
          <div>
            <Link href='/' aria-label='logo'>
              <Afishauz width={172} className='text-afisha' />
            </Link>
          </div>
          <nav className='ml-20 mr-auto'>
            <ul className='flex gap-x-7 items-center'>
              {menuItems.map(item => (
                <li key={item['@id']}>
                  <Link
                    href={item.permalink}
                    className='transition-colors hover:text-afisha'
                    target={item.target}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <ul className='flex gap-x-4 items-center text-gray-400'>
            <li className='mr-4'>
              <PushSubscribeButton />
            </li>
            <li>
              <a
                className='text-xl transition-colors hover:text-[var(--color-telegram)]'
                href={SOCIAL_MEDIA[locale].TELEGRAM}
                target='_blank'
                rel='noreferrer'
                aria-label='telegram'
              >
                <FontAwesomeIcon icon={faTelegram} />
              </a>
            </li>
            <li>
              <a
                className='text-xl transition-colors hover:text-[var(--color-facebook)]'
                href={SOCIAL_MEDIA[locale].FACEBOOK}
                target='_blank'
                rel='noreferrer'
                aria-label='facebook'
              >
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
            </li>
            <li>
              <a
                className='text-xl transition-colors hover:text-[var(--color-instagram-purple)]'
                href={SOCIAL_MEDIA[locale].INSTAGRAM}
                target='_blank'
                rel='noreferrer'
                aria-label='instagram'
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li>
              <a
                className='text-xl transition-colors hover:text-[var(--color-twitter)]'
                href={SOCIAL_MEDIA[locale].TWITTER}
                target='_blank'
                rel='noreferrer'
                aria-label='twitter'
              >
                <FontAwesomeIcon icon={faXTwitter} />
              </a>
            </li>
            <li>
              <a
                className='text-xl transition-colors hover:text-[var(--color-youtube)]'
                href={SOCIAL_MEDIA[locale].YOUTUBE}
                target='_blank'
                rel='noreferrer'
                aria-label='youtube'
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </li>
          </ul>
        </div>
        <div className='ml-[252px] max-w-[600px] text-gray-400'>
          <div className='text-sm mb-6'>
            {t.rich('layouts.desktop_footer.terms', {
              terms: chunks => (
                <Link href='/terms' className='link'>
                  {chunks}
                </Link>
              ),
            })}
          </div>
          <div className='text-sm mb-6'>
            {t.rich('layouts.desktop_footer.certificate', {
              br: () => <br />,
              email: chunks => (
                <a href='mailto:info@afisha.uz' className='link'>
                  {chunks}
                </a>
              ),
            })}
          </div>
          <div className='text-sm'>
            {t.rich('layouts.desktop_footer.copyright', {
              year: new Date().getFullYear(),
              br: () => <br />,
            })}
          </div>
        </div>
      </Container>
    </footer>
  );
}
