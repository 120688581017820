export function setCookie(
  name: string,
  value: string,
  {
    domain,
    expires,
    maxAge,
    partitioned,
    path,
    sameSite,
    secure,
  }: {
    domain?: string;
    expires?: Date;
    maxAge?: number;
    partitioned?: boolean;
    path?: string;
    sameSite?: 'lax' | 'strict' | 'none';
    secure?: boolean;
  } = {},
) {
  const cookieParts = [
    `${encodeURIComponent(name)}=${encodeURIComponent(value)}`,
  ];
  if (domain) {
    cookieParts.push(`Domain=${domain}`);
  }
  if (maxAge) {
    cookieParts.push(`Max-Age=${maxAge}`);
  } else if (expires) {
    cookieParts.push(`Expires=${expires.toUTCString()}`);
  }
  if (partitioned) {
    cookieParts.push('Partitioned');
  }
  if (path) {
    cookieParts.push(`Path=${path}`);
  }
  if (sameSite) {
    cookieParts.push(`SameSite=${sameSite}`);
  }
  if (secure) {
    cookieParts.push('Secure');
  }
  if (typeof window === 'object') {
    document.cookie = cookieParts.join('; ');
  }
}

export function parseCookies() {
  const cookie = typeof window === 'object' ? document.cookie : '';
  return Object.fromEntries(
    cookie
      .split('; ')
      .map(cookie => cookie.split('='))
      .map(([name, value]) => [
        decodeURIComponent(name),
        decodeURIComponent(value),
      ]),
  );
}
