import { type VariantProps, cva } from 'class-variance-authority';
import {
  type ComponentPropsWithoutRef,
  type ReactNode,
  forwardRef,
} from 'react';
import { twMerge } from 'tailwind-merge';

const input = cva(
  [
    'block',
    'w-full',
    'appearance-none',
    'rounded',
    'border',
    'border-gray-300',
    'outline-none',
    'focus:border-afisha',
    'webkit-appearance-none',
    'disabled:bg-gray-50',
    'disabled:cursor-default',
    'bg-transparent',
  ],
  {
    variants: {
      size: {
        sm: ['h-9', 'px-3', 'text-sm'],
        md: ['h-10', 'px-3.5'],
        lg: ['h-11', 'px-4'],
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);

const inputWithIcon = cva([], {
  variants: {
    size: {
      sm: ['pl-9'],
      md: ['pl-10'],
      lg: ['pl-11'],
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

const inputWrapper = cva(
  [
    'absolute top-0 left-0 bottom-0 z-10 flex items-center justify-center text-gray-400 text-sm',
  ],
  {
    variants: {
      size: {
        sm: ['w-9'],
        md: ['w-10'],
        lg: ['w-11'],
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);

export interface TextInputProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'size' | 'type'>,
    VariantProps<typeof input> {
  type?:
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'month'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';
  icon?: ReactNode;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ size, icon, className, ...rest }, ref) => {
    return (
      <div className={twMerge('relative', className)}>
        {icon && <div className={inputWrapper({ size })}>{icon}</div>}
        <input
          {...rest}
          ref={ref}
          className={twMerge(
            input({ size }),
            icon ? inputWithIcon({ size }) : null,
          )}
        />
      </div>
    );
  },
);

TextInput.displayName = 'TextInput';
